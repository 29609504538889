<template>
    <v-container>
        <v-snackbar v-model="snackbar" top :timeout="5000" :color="color">
            {{text}}
            <v-btn icon @click="snackbar = false"><v-icon color="white">mdi-close</v-icon></v-btn>
        </v-snackbar>
        <v-row>
            <v-col cols="12" md="8">
                <h1 class="blueDark--text">Get in Touch</h1>
                <div class="underline my-5"></div>
                <p>You can contact us any way that is convenient for you. We are available 24/7 via email. You can also use a quick contact form below or visit our office personally. We would be happy to answer your questions.</p>
                <v-form
                ref="contactForm"
                v-model="valid"
                >
                    <div class="d-flex flex-wrap">
                        <v-text-field v-model="firstName" outlined :rules="nameRules" label="First Name" class="px-1" required></v-text-field>

                        <v-text-field v-model="lastName" outlined :rules="nameRules" label="Last Name" class="px-1" required></v-text-field>
                    </div>

                    <div class="d-flex flex-wrap">
                        <v-text-field v-model="email" outlined :rules="emailRules" label="E-mail" class="px-1" required></v-text-field>
                        <phoneInput @update="assignPhone" class="px-1" />
                    </div>

                    <v-textarea v-model="message" outlined label="Message"></v-textarea>

                    <v-hover v-slot="{ hover }">
                        <v-btn :disabled="!valid" :loading="sendLoad" elevation="5" :color="hover ? 'blueDark' : 'orange'" class="white--text px-15 font-weight-bold d-block btn-trans" tile @click="submit">Send</v-btn>
                    </v-hover>
                </v-form>
            </v-col>
            <v-col cols="12" md="4">
                <v-card class="pa-5">
                    <v-card-title class="blueDark--text px-0 pt-0">Contact information</v-card-title>
                    <div><v-icon left color="blueLight">mdi-phone</v-icon><a href="tel:+833-287-3273">833-287-3273</a></div>
                    <v-divider class="my-5"></v-divider>
                    <div><v-icon left color="blueLight">mdi-email</v-icon><a href="mailto:support@atsfares.com">support@atsfares.com</a></div>
                    <v-divider class="my-5"></v-divider>
                    <div><v-icon left color="blueLight">mdi-map-marker</v-icon>131 West 33rd St. unit 10D, New York NY-10001</div>
                    <v-divider class="my-5"></v-divider>
                    <div class="mb-2"><v-icon left color="blueLight">mdi-calendar-clock</v-icon>Mon–Fri 8:00am–6:00pm</div>
                    <div class="mb-2"><v-icon left color="blueLight">mdi-calendar-clock</v-icon>Sat 10:00am–4:00pm</div>
                    <div><v-icon left color="blueLight">mdi-calendar-remove</v-icon>Sun Closed</div>
                </v-card>
            </v-col>
        </v-row>
        <iframe class="mt-5" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.552077287966!2d-73.99187808475718!3d40.749880679327724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259aeb80a4ac5%3A0xc527fbcf153d600e!2s131%20West%2033rd%20Street!5e0!3m2!1sen!2seg!4v1664890926635!5m2!1sen!2seg" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </v-container>
</template>

<script>
import phoneInput from '../components/mobile.vue'
import { contact, headersNoAuth } from '../links'

export default {
  components: {
    phoneInput
  },
  data: () => ({
    valid: true,
    firstName: '',
    lastName: '',
    nameRules: [
      v => (!!v && v.length > 1) || 'Item is required at least 2 characters',
      v => /^[A-Za-z][A-Za-z]*$/.test(v) || 'Name Must be letters only with no spaces'
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    message: '',
    phone: '',
    sendLoad: false,
    snackbar: false,
    color: '',
    text: ''
  }),

  methods: {
    assignPhone (phone) {
      this.phone = phone
    },
    submit () {
      this.$refs.contactForm.validate()
      if (this.valid) {
        if (this.phone.valid) {
          this.sendLoad = true
          const body = {
            name: this.firstName + ' ' + this.lastName,
            phone: this.phone.nationalNumber,
            email: this.email,
            message: this.message
          }
          this.$http.post(contact, body, { headers: headersNoAuth() }).then(response => {
            this.snackbar = true
            this.color = 'success'
            this.text = 'Your message was sent successfully, we will contact you soon.'
            this.$refs.contactForm.reset()
          }).catch(() => {
            this.snackbar = true
            this.color = 'error'
            this.text = 'Oops, Your message was not sent, try again later or contact us through other meduim.'
          }).finally(() => {
            this.sendLoad = false
          })
        } else {
          this.snackbar = true
          this.color = 'warning'
          this.text = 'Please enter a valid phone number'
        }
      } else {
        this.snackbar = true
        this.color = 'warning'
        this.text = 'Please fill all fields'
      }
    }
  }
}
</script>

<style>
    .underline {
        width: 150px;
        height: 2px;
        background-color: #FDB71A;
    }
</style>
